import { defineStore } from "pinia";

export interface PartnerLogo {
  small: string;
  medium: string;
  large: string;
}

export const useLogoStore = defineStore("Logo", {
  state: () => ({
    partnerLogo: {} as PartnerLogo | null,
  }),
  actions: {
    setPartnerLogo(payload: PartnerLogo | null) {
      this.partnerLogo = payload;
    },
  },
});
